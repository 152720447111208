import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { IonSelectCustomEvent, SelectChangeEventDetail } from '@ionic/core';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SubItem } from 'src/app/models/sub-item.model';

@Component({
  selector: 'app-filter-live-dialog',
  templateUrl: './filter-live.dialog.html',
  styleUrls: ['./filter-live.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainDialogComponent,
  ],
})
export class LiveFilterDialogComponent {
  private _modal = inject(ModalController);
  private navParams = inject(NavParams);

  selectedItem = signal<SubItem>(this.navParams.get('selected'));
  userList = this.navParams.get<SubItem[]>('users');

  selectedPremiumS = signal<SubItem>(this.navParams.get('selectedPremium'));
  premiumList = this.navParams.get<SubItem[]>('premiumPartners');

  onUser(event: IonSelectCustomEvent<SelectChangeEventDetail<SubItem>>) {
    const user = event.detail.value;
    this._modal.dismiss({ user });
  }

  onPremuim(event: IonSelectCustomEvent<SelectChangeEventDetail<SubItem>>) {
    const premiumPartner = event.detail.value;
    this._modal.dismiss({ premiumPartner });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
