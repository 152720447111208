  <app-main-dialog (dismiss)="dismiss()">
    <div desktopTitle>
      {{ name() }}
    </div>
    @if (isUser()) {
      <app-segment-list [segmentValue]="viewSegment()"
        [segmentButtons]="viewSegmentButtons"
        (segmentChanged)="viewSegment.set($event)"></app-segment-list>
    }
    @if (viewSegment() === 'allocate') {
      @if (scheduleSite() == null) {
        <app-select-site-button [selectedSite]="scheduleSite()"
          [items]="sites$ | async"
          (selected)="scheduleSite.set($event)"></app-select-site-button>
      }
      <form [formGroup]="formGroup">
        <ion-item>
          <ion-label>
            {{ scheduleSite()?.name }}
          </ion-label>
        </ion-item>
        @if (isUser()) {
          <ion-label>{{ 'schedule.add.project' | translate }}</ion-label>
          <ion-item>
            <ion-select aria-label=""
              label-placement="stacked"
              class="full-width"
              formControlName="project"
              interface="popover">
              @for (project of projects$ | async; track project) {
                <ion-select-option [value]="project">
                  {{ project.name }}
                </ion-select-option>
              }
            </ion-select>
          </ion-item>
        }
        <ion-item color="primary"
          lines="none">
          <ion-label>{{ 'schedule.startDate' | translate }}</ion-label>
          <app-select-day color="light"
            [id]="'selectStartDay'"
            [format]="'d.M.'"
            [expand]="''"
            [minDate]="minDate"
            [value]="formGroup.get('startDate').value"
            (changed)="onDateChange($event, 'startDate')"></app-select-day>
        </ion-item>
        <ion-item color="primary"
          lines="none">
          <ion-label>{{ 'schedule.endDate' | translate }}</ion-label>
          <app-select-day color="light"
            [id]="'selectEndDay'"
            [format]="'d.M.'"
            [expand]="''"
            [minDate]="formGroup.get('startDate').value"
            [value]="formGroup.get('endDate').value"
            (changed)="onDateChange($event, 'endDate')"></app-select-day>
        </ion-item>
        <ion-item color="secondary"
          lines="none">
          <ion-toggle [value]="includeWeekend()"
            (ionChange)="onIncludeWeekend($event)">
            {{ 'schedule.includeWeekend' | translate }}
          </ion-toggle>
        </ion-item>
        <ion-label>{{ 'schedule.add.text' | translate }}</ion-label>
        <ion-item>
          <ion-input autocapitalize="on"
            aria-label=""
            formControlName="text"
            type="text"></ion-input>
        </ion-item>
        @if (isUser()) {
          @if (machines$ | async; as machines) {
            <ion-button shape="round"
              size="small"
              color="secondary"
              (click)="selectMacines(machines)">
              @if (selectedMachine(); as machine) {
                {{ machine.name }}
              } @else {
                {{ 'schedule.add.machine' | translate }}
              }
            </ion-button>
          }
          <ion-button shape="round"
            size="small"
            color="secondary"
            (click)="selectTickets()">
            @if (selectedTickets().length > 0) {
              {{ selectedTickets().length }} {{ 'schedule.tickets' | translate }}
            } @else {
              {{ 'schedule.add.tickets' | translate }}
            }
          </ion-button>
          <ion-item button="true"
            id="open-date-start">
            <ion-label>{{ 'schedule.add.start' | translate }}</ion-label>
            <ion-text slot="end">{{ formGroup.get('start').value | date:'HH:mm' }}</ion-text>
            <ion-popover trigger="open-date-start"
              show-backdrop="false">
              <ng-template>
                <ion-datetime #projectStartDate
                  locale="fi-FI"
                  presentation="time"
                  formControlName="start"
                  [showDefaultButtons]="true"
                  [doneText]="'general.done' | translate"
                  [cancelText]="'general.cancel' | translate">
                </ion-datetime>
              </ng-template>
            </ion-popover>
          </ion-item>
          <ion-item button="true"
            id="open-date-end">
            <ion-label>{{ 'schedule.add.end' | translate }}</ion-label>
            <ion-text slot="end">{{ formGroup.get('end').value | date:'HH:mm' }}</ion-text>
            <ion-popover trigger="open-date-end"
              show-backdrop="false">
              <ng-template>
                <ion-datetime #projectEndDate
                  locale="fi-FI"
                  presentation="time"
                  formControlName="end"
                  [showDefaultButtons]="true"
                  [doneText]="'general.done' | translate"
                  [cancelText]="'general.cancel' | translate">
                </ion-datetime>
              </ng-template>
            </ion-popover>
          </ion-item>
        }
      </form>
      @if ((role$ | async) === 'admin') {
        @if (distance() > 0) {
          <ion-item>
            <ion-label>{{ 'sites.distance' | translate }}</ion-label>
            <div>{{ distance().toFixed(2) }}km</div>
          </ion-item>
        }
        @if (dayCost(); as dayCost) {
          <ion-item>
            <ion-label>{{ 'schedule.add.cost' | translate }}</ion-label>
            <div>{{ dayCost }}€</div>
          </ion-item>
        }
        @if (isEndCleaning()) {
          <ion-item>
            <ion-label>{{ 'schedule.add.contract' | translate }}</ion-label>
            <div>
              @if (actualHours$ | async; as actualHours) {
                <span>
                  {{ (actualHours / workday()) * workdayCost() }}€
                </span>
              }&nbsp;/ {{ scheduleSite()?.additionalInfo?.form?.totalSum }}€
            </div>
          </ion-item>
        }
      }
    } @else if (viewSegment() === 'absence') {
      <form class="ion-margin-bottom"
        [formGroup]="formGroup">
        <ion-button color="secondary"
          shape="round"
          (click)="onAbsence()">
          @if (formGroup.get('absence').value) {
            <span>
              {{ 'timesheet.absences.' + formGroup.get('absence').value | translate }}
            </span>
          } @else {
            {{ 'timesheet.absence' | translate }}
          }
        </ion-button>
        <ion-item color="primary"
          lines="none">
          <ion-label>{{ 'schedule.startDate' | translate }}</ion-label>
          <app-select-day color="light"
            [id]="'selectStartDay'"
            [format]="'d.M.'"
            [expand]="''"
            [minDate]="minDate"
            [value]="formGroup.get('startDate').value"
            (changed)="onDateChange($event, 'startDate')"></app-select-day>
        </ion-item>
        <ion-item color="primary"
          lines="none">
          <ion-label>{{ 'schedule.endDate' | translate }}</ion-label>
          <app-select-day color="light"
            [id]="'selectEndDay'"
            [format]="'d.M.'"
            [expand]="''"
            [minDate]="formGroup.get('startDate').value"
            [value]="formGroup.get('endDate').value"
            (changed)="onDateChange($event, 'endDate')"></app-select-day>
        </ion-item>
      </form>
    }
    <ion-button color="light"
      shape="round"
      (click)="onSave()"
      [disabled]="(viewSegment() !== 'absence' && formGroup.invalid)
      || (viewSegment() === 'absence' && formGroup.get('absence').value == null)">
      {{ 'general.save' | translate }}
    </ion-button>
  </app-main-dialog>