import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { startOfDay } from 'date-fns';
import { of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { RoomTicket, TicketStatus } from 'src/app/models/ticket.model';
import { AreasService } from 'src/app/services/areas.service';
import { ContractorsService } from 'src/app/services/contractors.service';
import { getRoomName } from 'src/app/services/worker-tickets.service';
import { MainDialogComponent } from '../../main-dialog/main-dialog.component';
import { SelectTicketsComponent, SelectedTicket } from '../select-tickets.component';

@Component({
  selector: 'app-select-tickets-dialog',
  templateUrl: './select-tickets.dialog.html',
  styleUrls: ['./select-tickets.dialog.scss'],
  imports: [
    CommonModule, AppCommonModule,
    MainDialogComponent,
    SelectTicketsComponent,
  ],
})
export class SelectTicketsDialog {
  private areasService = inject(AreasService);
  private contractorsService = inject(ContractorsService);
  private _modal = inject(ModalController);
  private navParams = inject(NavParams);

  selectedTickets = signal<RoomTicket[]>([]);

  tickets$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.areasService.getAreaTickets(of([this.navParams.get('site')]), contractor.guid)),
    map((tickets) => tickets.map((t) => t.tickets
      .filter((rt) => [TicketStatus.created].includes(rt.status) && !(rt.users?.length > 0))
      .map((rt) => {
        rt.room = t.room;
        rt.date = startOfDay(rt.history[rt.history.length - 1].timestamp);
        const roomName = getRoomName(rt);
        return Object.assign({}, rt, { selected: false, roomName });
      }) ?? []).flat()),
    shareReplay(1),
  );

  onSelectTicket(roomTickets: SelectedTicket[]) {
    this.selectedTickets.set(roomTickets);
  }

  done() {
    this._modal.dismiss({ tickets: this.selectedTickets() });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
