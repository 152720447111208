<app-main-page [hideHeader]="true">
  <div desktopTitle>
  </div>
  <div class="logo">
    <img src="./assets/images/logo.png">
  </div>
  @defer(on timer(3000)) {
    @if (isBillingAccount()) {
      @if (isIos) {
        <p class="order-ios">
          {{ 'stripe.orderAccount' | translate }}
        </p>
      } @else {
        <p>{{ 'stripe.orderAccount' | translate }}</p>
        @if (adminProduct$ | async; as product) {
          @if (product.currentPrice$ | async; as price) {
            <ion-item button="true"
              lines="none"
              (click)="onOrder(price)">
              <app-loading-button [loading]="loading()">
                <div>
                  <h4>{{ 'tickets.freemium.order' | translate }} <span
                      class="name">{{ 'product.admin.name' | translate }}</span></h4>
                  <p>{{ 'product.admin.description' | translate }}</p>
                </div>
              </app-loading-button>
              <div slot="end">
                {{ price.price | currency:'EUR' }}
              </div>
            </ion-item>
          }
        }
      }
    } @else {
      <p>{{ 'stripe.noActivated' | translate }}</p>
      <p>{{ billingAccountName() }}</p>
    }
    <div class="ion-margin-top">
      <ion-button color="light"
        shape="round"
        (click)="logout()">
        {{ 'profile.logout' | translate }}
      </ion-button>
    </div>
  } @placeholder {
    <div class="ion-margin-top">
      <app-loading></app-loading>
    </div>
  }
</app-main-page>