import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, signal } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { ModalController } from '@ionic/angular/standalone';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { NoResultsComponent } from 'src/app/components/no-results/no-results.component';
import { SegmentListComponent } from 'src/app/components/segment-list/segment-list.component';
import { ContractorOffer } from 'src/app/models/contractor-offer.model';
import { TimestampPipe } from 'src/app/pipes/timestamp/timestamp.pipe';
import { ContractorOffersService } from 'src/app/services/contractor-offers.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';
import { sortByTimestamp } from 'src/app/utility/sort';
import { ContractorOfferDialogComponent } from '../contractor-offer.dialog';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    TimestampPipe,
    NoResultsComponent,
    SegmentListComponent,
  ],
})
export class OffersComponent {
  private contractorOffersService = inject(ContractorOffersService);
  private modalCtrl = inject(ModalController);
  private usersService = inject(UsersService);

  sub = input(false);

  segmentValue = signal('offers');

  segmentButtons = [
    {
      value: 'offers',
      name: 'partner.createdOffers',
      show: true,
    },
    {
      value: 'contracts',
      name: 'partner.contracts',
      show: true,
    },
  ];

  offers$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    switchMap((user) => user.role === Roles.superAdmin ? this.contractorOffersService.getList({ status: 'created' }) : this.usersService.isPremiumPartner(user) ?
      this.contractorOffersService.getList({ partners: [user.guid, ...user.partners], status: 'created' }) :
      this.contractorOffersService.getList({ partner: user.guid, status: 'created' })),
    map((items) => items.sort((a, b) => sortByTimestamp(a, b, 'date'))),
    shareReplay(1),
  );

  contracts$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    switchMap((user) => user.role === Roles.superAdmin ? this.contractorOffersService.getList({ status: 'accepted' }) : this.usersService.isPremiumPartner(user) ?
      this.contractorOffersService.getList({ partners: [user.guid, ...user.partners], status: 'accepted' }) :
      this.contractorOffersService.getList({ partner: user.guid, status: 'accepted' })),
    map((items) => items.sort((a, b) => sortByTimestamp(a, b, 'date'))),
    shareReplay(1),
  );

  role = computed(() => this.usersService.currentUserS()?.role);

  segmentChanged(value: string) {
    if (value) {
      this.segmentValue.set(value);
    }
  }

  createOffer() {
    this.modalCtrl.create({
      component: ContractorOfferDialogComponent,
      cssClass: ['modal-tall'],
      backdropDismiss: false,
    }).then((m) => {
      m.present();
    });
  }

  onOffer(offer: ContractorOffer) {
    this.modalCtrl.create({
      component: ContractorOfferDialogComponent,
      componentProps: { item: offer },
      cssClass: ['modal-tall'],
      backdropDismiss: false,
    }).then((m) => {
      m.present();

      m.onDidDismiss<ContractorOffer>().then((data) => {
        if (data.data) {
          const item = Object.assign({}, offer, data.data);
          this.contractorOffersService.update(item);
        }
      });
    });
  }

  onContract(contract: ContractorOffer) {
    Browser.open({ url: contract.contractUrl });
  }
}
