import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SegmentCustomEvent } from '@ionic/angular/standalone';
import { endOfToday, isAfter } from 'date-fns';
import { filter, map, shareReplay, switchMap } from 'rxjs';
import { AppCommonModule } from '../common.module';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { ContractorsService } from '../services/contractors.service';
import { PartnerInviteService } from '../services/partner-invite.service';
import { UsersService } from '../services/users.service';
import { isTimestamp } from '../utility/time';

@Component({
  selector: 'app-invites-page',
  templateUrl: './invites.page.html',
  styleUrls: ['./invites.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainPageComponent,
  ],
})
export class InvitesPage {

  private invites$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    switchMap((user) => this.service.getList({ inviter: user.guid })),
    map((invites) => invites.map((invite) => {
      const item = Object.assign({}, invite);
      if (isTimestamp(item.created)) {
        item.created = item.created.toDate();
      }
      return item;
    })),
    shareReplay(1),
  );

  segmentValue = 'accepted';

  acceptedInvites$ = this.invites$.pipe(
    map((invites) => invites.filter((i) => i.contractor != null)),
    map((invites) => invites.map((i) => {
      const item = Object.assign({}, i, { contractor$: this.contractorsService.get(i.guid) });
      return item;
    })),
    shareReplay(1),
  );

  waitingInvites$ = this.invites$.pipe(
    map((invites) => invites.filter((i) => i.contractor == null)),
    map((invites) => invites.filter((i) => isAfter(i.expires.toDate(), endOfToday()))),
    shareReplay(1),
  );

  constructor(
    private contractorsService: ContractorsService,
    private service: PartnerInviteService,
    private usersService: UsersService,
  ) { }

  segmentChanged(event: SegmentCustomEvent) {
    this.segmentValue = event.detail.value.valueOf() as string;
  }
}
