import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { Schedule } from 'src/app/models/schedule.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule.dialog.html',
  styleUrls: ['./schedule.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainDialogComponent,
  ],
})
export class ScheduleDialogComponent {
  private _modal = inject(ModalController);
  private navParams = inject(NavParams);
  private sitesService = inject(SitesService);

  schedule = this.navParams.get<Schedule>('schedule');
  user = this.navParams.get<SubItem>('user');
  site = this.sitesService.getSync(this.schedule.site);

  userTime = this.schedule?.times.find((it) => it.user === this.user?.guid);

  dismiss() {
    this._modal.dismiss();
  }
}
