<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'general.filters' | translate }}
  </div>
  <p>{{ 'general.filterInfo' | translate }}</p>
  <h4>{{ 'users.title' | translate }}</h4>
  <ion-item lines="none">
    <ion-select aria-label=""
      label-placement="stacked"
      class="full-width"
      [value]="selectedItem()"
      [placeholder]="'general.select' | translate"
      interface="popover"
      (ionChange)="onUser($event)">
      @for (user of userList; track user.guid) {
        <ion-select-option [value]="user">
          {{ user.name }}
        </ion-select-option>
      }
    </ion-select>
  </ion-item>
  @if (premiumList?.length > 0) {
    <h4>{{ 'live.premiumPartner' | translate }}</h4>
    <ion-item lines="none">
      <ion-select aria-label=""
        label-placement="stacked"
        class="full-width"
        [value]="selectedPremiumS()"
        [placeholder]="'general.select' | translate"
        interface="popover"
        (ionChange)="onPremuim($event)">
        @for (user of premiumList; track user.guid) {
          <ion-select-option [value]="user">
            {{ user.name }}
          </ion-select-option>
        }
      </ion-select>
    </ion-item>
  }
</app-main-dialog>