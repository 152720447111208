import { Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { IService } from '../models/service.interface';
import { Tag } from '../models/tag.model';
import { FirestoreService } from './firestore.service';

const itemCollection = 'tags';

@Injectable({
  providedIn: 'root',
})
export class TagsService implements IService<Tag> {

  constructor(private firestore: FirestoreService) { }

  async save(item: Tag) {
    return this.firestore.save<Tag>(itemCollection, item);
  }

  async update(item: Tag): Promise<void> {
    return this.firestore.update(itemCollection, item);
  }

  get(guid: string): Observable<Tag> {
    return this.firestore.get<Tag>(itemCollection, guid);
  }

  getList(options?: { contractor: string; type?: 'team' | 'area' }): Observable<Tag[]> {
    const queryConstraints = [];
    if (options?.contractor) {
      queryConstraints.push(where('contractor', '==', options.contractor));
    }
    if (options?.type) {
      queryConstraints.push(where('type', '==', options.type));
    }

    return this.firestore.getList<Tag>(itemCollection, undefined, queryConstraints);
  }

  async delete(item: Tag) {
    return this.firestore.delete(itemCollection, item);
  }
}
