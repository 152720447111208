import { computed, inject, Injectable, signal } from '@angular/core';
import { UsersService } from './users.service';

export const THEMES = [
  { value: '', color: '#036291' },
  { value: 'tealGreen', color: '#1B7A7A' },
  { value: 'sandstone', color: '#C49B5E' },
  { value: 'charcoalGray', color: '#434343' },
  { value: 'palePink', color: '#E0BFB8' },
  { value: 'brickRed', color: '#A1321B' },
  { value: 'roseRed', color: '#B82258' },
  { value: 'forestGreen', color: '#328A50' },
];

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private usersService = inject(UsersService);

  private currentTheme = signal('');

  async setTheme(theme: string) {
    this.currentTheme.set(theme);
    return this.usersService.updateOnly(this.usersService.currentUserS().guid, { theme });
  }

  getTheme() {
    return computed(() => {
      const user = this.usersService.currentUserS();
      return user?.theme ?? this.currentTheme();
    });
  }
}
