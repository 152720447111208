import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeFiExtra from '@angular/common/locales/extra/fi';
import localeFi from '@angular/common/locales/fi';
import { enableProdMode, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { Capacitor } from '@capacitor/core';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateModule } from '@ngx-translate/core';
import { INavigationService, TokenInterceptor } from '@scandium-oy/ngx-scandium';
import { init } from '@sentry/angular';
import { addIcons } from 'ionicons';
import * as ionIcons from 'ionicons/icons';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { GlobalErrorHandler } from './app/handlers/global-error.handler';
import { NavigationService } from './app/services/navigation.service';
import { translateConfig } from './app/utility/translateConfig';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

init({
  dsn: environment.sentry,
  debug: !environment.production,
  ignoreErrors: [
    'ResizeObserver loop completed with undelivered notifications.',
    'ResizeObserver loop limit exceeded',
  ],
});

registerLocaleData(localeFi, 'fi-FI', localeFiExtra);

const circleConfig = {
  radius: 100,
  outerStrokeWidth: 16,
  innerStrokeWidth: 8,
  outerStrokeColor: '#78C000',
  innerStrokeColor: '#C7E596',
  animationDuration: 300,
};

// Import all icons for now
addIcons(ionIcons);

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: INavigationService, useClass: NavigationService },
    { provide: LOCALE_ID, useValue: 'fi-FI' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideIonicAngular({
      backButtonText: '',
      backButtonIcon: 'chevron-back-outline',
    }),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideFunctions(() => getFunctions(undefined, environment.functionRegion)),
    importProvidersFrom([
      IonicStorageModule.forRoot({
        name: 'vvDb',
      }),
      TranslateModule.forRoot(translateConfig),
      NgCircleProgressModule.forRoot(circleConfig),
      NgScrollbarModule,
    ]),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !Capacitor.isNativePlatform() && environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
});

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
