<app-main-page>
  <div desktopTitle>
    {{ 'nav.importProspects' | translate }}
  </div>
  <div class="ion-margin-bottom">
    @if (premiumPartners$ | async; as users) {
      <app-select-user-button [items]="users"
        [selectedUser]="selectedUser()"
        (selected)="selectUser($event)"></app-select-user-button>
    }
  </div>
  <ion-button color="light"
    [disabled]="selectedUser() == null"
    shape="round">
    <form method="post"
      enctype="multipart/form-data"
      class="input-form">
      <label class="input-label"
        for="file">
        <input id="file"
          type="file"
          name="file"
          accept=".csv,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.ms-excel"
          (change)="handleFileInput($event)" />
        {{ 'attachment.select' | translate }}
      </label>
    </form>
  </ion-button>
  @if (error()) {
    <p>{{ 'import.error' | translate }}</p>
  }
  @if (prospects().length > 0) {
    <p>{{ 'import.found' | translate:{ num: prospects().length} }}</p>
    <ion-button color="light"
      (click)="save()">
      {{ 'general.save' | translate }}
    </ion-button>
  }
</app-main-page>