<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'schedule.selectTickets' | translate }}
  </div>
  <app-select-tickets [tickets]="tickets$ | async"
    (selectedTickets)="onSelectTicket($event)"></app-select-tickets>
  <div class="ion-margin-top">
    <ion-button color="light"
      shape="round"
      (click)="done()">
      {{ 'general.done' | translate }}
    </ion-button>
  </div>
</app-main-dialog>