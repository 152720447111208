<app-main-page [showScroll]="true">
  <div desktopTitle>
    {{ 'nav.worklist' | translate }}
  </div>
  @if ({
    items: items$ | async
  }; as vm) {
    <div class="flex-justify-between">
      <div>
        @if (roleS() !== 'worker') {
          <ion-button color="secondary"
            (click)="onFilterTag('area')">
            {{ filterArea()?.name ?? 'shift.area' | translate }}
          </ion-button>
          <ion-button color="secondary"
            (click)="onFilterTag('team')">
            {{ filterTeam()?.name ?? 'shift.team' | translate }}
          </ion-button>
        }
      </div>
      <app-week-switch (dateChanged)="onDateChange($event)"></app-week-switch>
      @if (hasDrafts()) {
        <div>
          <ion-button color="light"
            (click)="onPublish(vm.items)">
            {{ 'general.publish' | translate }}
          </ion-button>
        </div>
      }
    </div>
    <table>
      <tr class="info">
        <td>
        </td>
        @if (!mobileView()) {
          <td>
          </td>
          <td>
          </td>
        }
        @for (day of days(); track day.date) {
          <td>
            @if (day.isPeriodEnd) {
              <div>
                <div [title]="'users.levelingPeriodChanges' | translate">
                  <ion-icon name="information-circle-outline"></ion-icon>
                </div>
              </div>
            }
          </td>
        }
      </tr>
      <tr class="header">
        <td>
          <div class="phase-name">
            {{ 'shift.person' | translate }}
          </div>
        </td>
        @if (!mobileView()) {
          <td>
            <div class="phase-name">
              {{ 'shift.team' | translate }}
            </div>
          </td>
          <td>
            <div class="phase-name">
              {{ 'shift.area' | translate }}
            </div>
          </td>
        }
        @for (day of days(); track day.date) {
          <td [class.weekend]="day.isWeekend"
            [class.period-start]="day.isPeriodStart"
            [class.period-end]="day.isPeriodEnd">
            <div class="item">
              {{ day.date | date:'d.M.' }}
            </div>
          </td>
        }
      </tr>
      @for (item of vm.items; track item.guid) {
        <tr>
          <td class="link"
            (click)="onUser(item)">
            <div class="phase-name">
              {{ item.name }}
              @if (mobileView()) {
                <br /><ion-note>{{ item.teams }}</ion-note>
                <br /><ion-note>{{ item.areas }}</ion-note>
              }
            </div>
          </td>
          @if (!mobileView()) {
            <td>
              <div class="phase-name">
                {{ item.teams }}
              </div>
            </td>
            <td>
              <div class="phase-name">
                {{ item.areas }}
              </div>
            </td>
          }
          @for (day of days(); track day.date) {
            @let schedule = getItem(day.dateString, item.guid, item.schedules);
            <td [class.absence]="schedule.absence"
              [class.has-item]="schedule.timeStatus === 'published'"
              [class.has-draft]="schedule.timeStatus === 'draft'"
              [class.full-hours]="item.fullHours"
              [class.weekend]="day.isWeekend"
              [class.period-start]="day.isPeriodStart"
              [class.period-end]="day.isPeriodEnd"
              class="link"
              (click)="onItem(day.date, item, item.schedules)">
              <div class="item">
                @if (schedule.hours) {
              <ion-badge>{{ schedule.hours.hours }},{{ schedule.hours.minutes }}h</ion-badge>
            }
            </div>
            </td>
          }
        </tr>
        } @empty {
          <div class="ion-margin-top">
            <app-no-results></app-no-results>
          </div>
        }
    </table>
    }
</app-main-page>