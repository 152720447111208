import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { ModalController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { filter, shareReplay, switchMap } from 'rxjs';
import { AppCommonModule } from '../common.module';
import { ChevronComponent } from '../components/chevron/chevron.component';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { NoResultsComponent } from '../components/no-results/no-results.component';
import { SegmentListComponent } from '../components/segment-list/segment-list.component';
import { WorkMachine } from '../models/work-machine.model';
import { ContractorsService } from '../services/contractors.service';
import { PlusService } from '../services/plus.service';
import { WorkMachinesService } from '../services/work-machines.service';
import { WorkMachineDialogComponent } from './dialog/work-machine.dialog';

@Component({
  selector: 'app-work-machines-page',
  templateUrl: './work-machines.page.html',
  styleUrls: ['./work-machines.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    ChevronComponent,
    NoResultsComponent,
    MainPageComponent,
    SegmentListComponent,
  ],
})
export class WorkMachinesPage {

  machines$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.workMachinesService.getList({ contractor: contractor.guid })),
    shareReplay(1),
  );

  segmentButtons = [
    {
      name: 'workMachines.newItem',
      show: true,
      action: () => this.onNew(),
      cssClass: 'desktop',
    },
  ];

  constructor(
    private contractorsService: ContractorsService,
    private modalCtrl: ModalController,
    private plusService: PlusService,
    private translate: TranslateService,
    private workMachinesService: WorkMachinesService,
  ) { }

  onNew() {
    this.plusService.onWorkMachine();
  }

  edit(item: WorkMachine) {
    this.modalCtrl.create({ component: WorkMachineDialogComponent, componentProps: { item } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          this.workMachinesService.update(data.data);
        }
      });
    });
  }

  delete(item: WorkMachine) {
    Dialog.confirm({
      message: this.translate.instant('general.delete'),
      okButtonTitle: this.translate.instant('general.yes'),
      cancelButtonTitle: this.translate.instant('general.no'),
    }).then((dialog) => {
      if (dialog.value) {
        item.deleted = true;
        this.workMachinesService.update(item);
      }
    });
  }
}
