import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { ToastController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { Papa } from 'ngx-papaparse';
import { map, shareReplay } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainPageComponent } from 'src/app/components/main-page/main-page.component';
import { SelectedUser, SelectUserButtonComponent } from 'src/app/components/select-user/select-user.component';
import { PartnerNote } from 'src/app/models/partner-note.model';
import { PartnerNotesService } from 'src/app/services/partner-notes.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';
import * as XLSX from 'xlsx';

interface ProspectData {
  ['Y-tunnus']: string;
  ['Virallinen nimi']: string;
  ['Titteli']: string;
  ['Etunimi']: string;
  ['Sukunimi']: string;
  ['Puhelinnumero']: string;
  ['Sähköpostiosoite']: string;
  ['Henkilöstö']: string;
  ['Liikevaihtoluokka']: string;
  ['Päätoimiala (Profinder)']: string;
  ['WWW-osoite']: string;
}

@Component({
  selector: 'app-import-prospects-page',
  templateUrl: './import-prospects.page.html',
  styleUrls: ['./import-prospects.page.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    MainPageComponent,
    SelectUserButtonComponent,
  ],
})
export class ImportProspectsPage {
  private papa = inject(Papa);
  private partnerNotesService = inject(PartnerNotesService);
  private toastCtrl = inject(ToastController);
  private translate = inject(TranslateService);
  private usersService = inject(UsersService);

  premiumPartners$ = this.usersService.getList(null, { role: Roles.partner }).pipe(
    map((users) => users.filter((it) => it.rights?.premiumPartner)),
    shareReplay(1),
  );

  prospects = signal<PartnerNote[]>([]);
  selectedUser = signal<SelectedUser>(null);
  error = signal<boolean>(false);

  selectUser(selectedUser: SelectedUser) {
    this.selectedUser.set(selectedUser);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleFileInput(event: any) {
    const files = event.target.files as FileList;
    const reader = new FileReader();
    if (files[0].name.endsWith('.csv')) {
      reader.readAsText(files[0]);
    } else {
      reader.readAsBinaryString(files[0]);
    }

    reader.onload = () => {
      let buf = reader.result;
      let parsed;
      if (!files[0].name.endsWith('.csv')) {
        const wb: XLSX.WorkBook = XLSX.read(buf, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        buf = XLSX.utils.sheet_to_csv(ws, { blankrows: false });
      }

      parsed = this.papa.parse((buf as string), { skipEmptyLines: true, header: true, dynamicTyping: true });
      const data = parsed.data as ProspectData[];
      const prospects = data.map((it) => ({
        inviter: this.selectedUser().guid,
        target: it['Virallinen nimi'],
        date: Timestamp.now(),
        history: [{
          status: 'prospect',
          user: { guid: this.usersService.currentUserS().guid, name: this.usersService.currentUserS().displayName },
          date: Timestamp.now(),
        }],
        status: 'prospect',
        turnoverClass: it['Liikevaihtoluokka'] ?? null,
        personnel: it['Henkilöstö'] ?? null,
        information: `${it['Titteli']} ${it['Etunimi']} ${it['Sukunimi']}\n${it['Puhelinnumero']}\n${it['Sähköpostiosoite']}\nPäätoimiala: ${it['Päätoimiala (Profinder)']}\nWWW: ${it['WWW-osoite']}`,
        notes: '',
      } as PartnerNote));
      this.prospects.set(prospects);
    };

    reader.onerror = () => {
      console.error('error is occured while reading file!');
      this.error.set(true);
    };
  }

  save() {
    this.prospects().map((it) => {
      this.partnerNotesService.save(it);
    });
    this.prospects.set([]);
    this.selectedUser.set(null);
    this.toastCtrl.create({
      message: this.translate.instant('general.saved'),
      position: 'middle',
      color: 'success',
      duration: 5000,
      icon: 'checkmark-circle-outline',
    }).then(async (toast) => toast.present());
  }
}
