import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, HostListener, Input, ViewChild, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { memo } from '@scandium-oy/ngx-scandium';
import { addDays, addMinutes, endOfDay, isBefore, previousMonday, startOfDay, startOfToday } from 'date-fns';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, map, shareReplay, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { ScrollService } from 'src/app/services/scroll.service';
import { mobileWidth } from 'src/app/utility/constants';

const defaultDuration = 60;
const defaultScrollTo = '06.00';

export interface CalendarItem<T> {
  guid: string;
  height?: string;
  color?: string;
  right?: string;
  width?: string;
  duration: number;
  item: T;
  name: string;
  subtitle?: string;
  information?: string;
  startDate: Date;
  endDate: Date;
}

interface ITime<T> {
  start: Date;
  duration: number;
  items: CalendarItem<T>[];
}

interface IDay<T> {
  weekday: number;
  date: Date;
  times: ITime<T>[];
}

const getInitialDate = () => {
  const today = startOfToday();
  if (today.getDay() === 1) {
    return today;
  }
  return previousMonday(today);
};

function getTimes<T>(date: Date, items: CalendarItem<T>[]): ITime<T>[] {
  const ret = [];
  let curr = startOfDay(date);
  const max = endOfDay(date);
  while (curr <= max) {
    const next = addMinutes(curr, defaultDuration);
    const item: ITime<T> = {
      start: curr,
      duration: defaultDuration,
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      items: items.filter((it) => !isBefore(it.startDate, curr) && isBefore(it.startDate, next)),
    };
    ret.push(item);
    curr = addMinutes(curr, defaultDuration);
  }
  return ret;
}

@Component({
  selector: 'app-week-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    NgScrollbarModule,
  ],
})
export class WeekCalendarComponent<T> {
  private document = inject(DOCUMENT);
  private scrollService = inject(ScrollService);

  isMobileView = signal(false);

  selectedDate$ = new BehaviorSubject<Date>(getInitialDate());
  private endDate$ = combineLatest([this.selectedDate$.asObservable(), toObservable(this.isMobileView)]).pipe(
    map(([date, isMobile]) => {
      const days = isMobile ? 4 : 6;
      return endOfDay(addDays(date, days));
    }),
  );

  itemsS = signal<CalendarItem<T>[]>([]);
  subId = signal<string>(null);

  times$ = this.selectedDate$.pipe(
    map((date) => getTimes<T>(date, [])),
    tap(() => {
      setTimeout(() => this.scrollTo(`${this.subId()}${defaultScrollTo}`), 50);
    }),
    shareReplay(1),
  );

  days$ = combineLatest([this.selectedDate$.asObservable(), this.endDate$, toObservable(this.itemsS)]).pipe(
    filter(([startDate, endDate]) => isBefore(startDate, endDate)),
    debounceTime(500),
    map(([startDate, endDate, itemsS]) => {
      const ret: IDay<T>[] = [];
      const items = itemsS.map((it) => {
        it.color = it.color ?? '#fff';
        it.height = `${100 * (it.duration === defaultDuration ? 1 : it.duration / defaultDuration)}%`;
        const position = this.calculatePosition(it, itemsS);
        it.right = position.right;
        it.width = `${position.width}%`;
        return it;
      });
      let curr = startOfDay(startDate);
      while (curr <= endDate) {
        const item: IDay<T> = {
          weekday: curr.getDay(),
          date: new Date(curr),
          times: getTimes<T>(curr, items),
        };
        ret.push(item);
        curr = addDays(curr, 1);
      }
      return ret;
    }),
    shareReplay(1),
  );

  @Input()
  set items(value: CalendarItem<T>[]) {
    this.onResize();
    this.itemsS.set(value);
  }

  @Input()
  set calendarId(value: string) {
    this.subId.set(value);
  }

  @ViewChild(NgScrollbar)
  scrollbar: NgScrollbar;

  getDayTime = memo((time: ITime<T>, day: IDay<T>) =>
    day.times.find((it) => `${it.start.getHours()}:${it.start.getMinutes()}` === `${time.start.getHours()}:${time.start.getMinutes()}`));

  private calculatePosition(item: CalendarItem<T>, items: CalendarItem<T>[]) {
    const atSameTime = new Set<string>();
    let date = new Date(item.startDate);
    while (date <= item.endDate) {
      // eslint-disable-next-line @typescript-eslint/no-loop-func
      items.filter((it) => !isBefore(date, it.startDate) && isBefore(date, it.endDate)).map((it) => it.guid).map((it) => atSameTime.add(it));
      date = addMinutes(date, defaultDuration);
    }
    const index = Array.from(atSameTime).sort().indexOf(item.guid);
    const width = 100 / atSameTime.size;
    const right = `${(width * index) + 1}%`;
    return { right, width: width - 2 };
  }

  private scrollTo(id: string) {
    const elementInScrollableDiv = this.document.getElementById(id);
    if (elementInScrollableDiv != null) {
      if (this.isMobileView()) {
        this.scrollService.scrollTo(elementInScrollableDiv, { top: -170 });
      } else {
        this.scrollbar.scrollToElement(elementInScrollableDiv);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobileView.set(window.innerWidth < mobileWidth);
  }
}
