import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AppCommonModule } from '../common.module';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { TimesheetsComponent } from './timesheets/timesheets.component';

@Component({
  selector: 'app-timesheets-page',
  templateUrl: './timesheets.page.html',
  styleUrls: ['./timesheets.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    TimesheetsComponent,
    MainPageComponent,
  ],
})
export class TimesheetsPage {

}
