import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingComponent } from '@scandium-oy/ngx-scandium';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainPageComponent } from 'src/app/components/main-page/main-page.component';
import { TimestampPipe } from 'src/app/pipes/timestamp/timestamp.pipe';
import { AreasService } from 'src/app/services/areas.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PlusService } from 'src/app/services/plus.service';
import { SitesService } from 'src/app/services/sites.service';
import { StripeService } from 'src/app/services/stripe.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-stripe-success-page',
  templateUrl: './success.page.html',
  styleUrls: ['./success.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainPageComponent,
    LoadingComponent,
    TimestampPipe,
  ],
})
export class StripeSuccessPage {
  private areasService = inject(AreasService);
  private plusService = inject(PlusService);
  private route = inject(ActivatedRoute);
  private navigationService = inject(NavigationService);
  private sitesService = inject(SitesService);
  private stripeService = inject(StripeService);
  private usersService = inject(UsersService);

  priceWithVat = signal<{ price: number; percentage: number }>(null);

  user$ = this.route.queryParams.pipe(
    switchMap((params) => this.usersService.get(params['user'])),
    shareReplay(1),
  );

  subscription$ = this.user$.pipe(
    filter((user) => user != null),
    switchMap((user) => this.usersService.getUserSubscriptions(null, { worker: user.guid })),
    map((subs) => subs.length > 0 ? subs[0] : null),
    tap(async (sub) => {
      const item = sub.items[0];
      const taxRate = await this.stripeService.getTaxRate();
      const price = {
        price: (item.price.unit_amount * (taxRate.percentage / 100) + item.price.unit_amount) / 100,
        percentage: taxRate.percentage,
      };
      this.priceWithVat.set(price);
    }),
    shareReplay(1),
  );

  params$ = combineLatest([this.route.queryParams, this.sitesService.getActiveList()]).pipe(
    filter(([_, sites]) => sites?.length > 0),
    debounceTime(1000),
    tap(([params, sites]) => {
      if (params['parent'] === 'undefined') {
        // this.navigationService.navigateToHome();
      } else if (params['parent'] !== 'null') {
        this.areasService.openRoomTicket(params['parent'], params['ticket'], true);
      } else {
        this.plusService.onAdHoc(sites, null, null, true);
      }
    }),
    shareReplay(1),
  );

  home() {
    this.navigationService.navigateToHome();
  }
}
