import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { AppCommonModule } from 'src/app/common.module';

@Component({
  selector: 'app-first-login-dialog',
  templateUrl: './first-login.dialog.html',
  styleUrls: ['./first-login.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,

  ],
})
export class FirstLoginDialogComponent {

  private _modal = inject(ModalController);

  step = signal(1);

  skip() {
    this.step.set(6);
  }

  nextStep() {
    this.step.update((val) => ++val);
  }

  dismiss() {
    this._modal.dismiss();
  }

}
