import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { debounceTime, filter, map, shareReplay, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainPageComponent } from 'src/app/components/main-page/main-page.component';
import { AreasService } from 'src/app/services/areas.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-ticket-page',
  templateUrl: './ticket.page.html',
  styleUrls: ['./ticket.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainPageComponent,
  ],
})
export class TicketPageComponent {
  private route = inject(ActivatedRoute);
  private areasService = inject(AreasService);
  private navigationService = inject(NavigationService);
  private usersService = inject(UsersService);

  ticket$ = combineLatest([this.usersService.getCurrentUser(), this.route.queryParams]).pipe(
    filter(([user, params]) => user != null && params != null),
    map(([_, params]) => this.areasService.openRoomTicket(params['guid'], params['ticket'], false, ['modal-fullscreen'])),
    debounceTime(200),
    tap(async () => this.navigationService.navigateToHome()),
    shareReplay(1),
  );
}
