import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AppCommonModule } from '../common.module';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,

  ],
})
export class PrivacyComponent {

}
