import { CommonModule } from '@angular/common';
import { Component, computed, EnvironmentInjector, HostListener, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ModalController } from '@ionic/angular/standalone';
import { fieldSorter, SelectDialogComponent } from '@scandium-oy/ngx-scandium';
import { addDays, addWeeks, isBefore, isWithinInterval, startOfDay, startOfToday, startOfWeek } from 'date-fns';
import { combineLatest, firstValueFrom, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppCommonModule } from '../common.module';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { NoResultsComponent } from '../components/no-results/no-results.component';
import { WeekSwitchComponent } from '../components/week-switch/week-switch.component';
import { Schedule } from '../models/schedule.model';
import { SubItem } from '../models/sub-item.model';
import { Tag } from '../models/tag.model';
import { AddScheduleDialogComponent } from '../schedule/dialog/add-schedule/add-schedule.dialog';
import { ContractorsService } from '../services/contractors.service';
import { NavigationService } from '../services/navigation.service';
import { SchedulesService } from '../services/schedules.service';
import { TagsService } from '../services/tags.service';
import { UsersService } from '../services/users.service';
import { defaultLevelingPeriodWeeks, mobileWidth } from '../utility/constants';
import { Roles } from '../utility/role';
import { ScheduleDialogComponent } from './schedule/schedule.dialog';

function getCurrentLevelingPeriod(selectedDate: Date, levelingStart: Date, levelingPeriodWeeks: number) {
  if (isBefore(selectedDate, levelingStart)) {
    const end = addDays(addWeeks(selectedDate, levelingPeriodWeeks), -1);
    return { start: selectedDate, end };
  }
  let currentLevelingStart = levelingStart;
  let currentLevelingEnd = addDays(addWeeks(currentLevelingStart, levelingPeriodWeeks), -1);
  while (!isWithinInterval(selectedDate, { start: currentLevelingStart, end: currentLevelingEnd })) {
    currentLevelingStart = startOfDay(addDays(currentLevelingEnd, 1));
    currentLevelingEnd = addDays(addWeeks(currentLevelingStart, levelingPeriodWeeks), -1);
  }
  return { start: currentLevelingStart, end: currentLevelingEnd };
}

@Component({
  selector: 'app-shift-page',
  templateUrl: './shift.page.html',
  styleUrls: ['./shift.page.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    MainPageComponent,
    WeekSwitchComponent,
    NoResultsComponent,
  ],
})
export class ShiftPage {
  private contractorsService = inject(ContractorsService);
  private injectorContext = inject(EnvironmentInjector);
  private modalCtrl = inject(ModalController);
  private navigationService = inject(NavigationService);
  private schedulesService = inject(SchedulesService);
  private tagsService = inject(TagsService);
  private usersService = inject(UsersService);

  private selectedDayS = signal<Date>(startOfWeek(startOfToday(), { weekStartsOn: 1 }));
  mobileView = signal(false);

  days = computed(() => {
    const contractor = this.contractorsService.contractorS();
    if (contractor != null) {
      return this.getDays(this.mobileView(), this.selectedDayS());
    } else {
      return [];
    }
  });

  filterArea = signal<Tag>(null);
  filterTeam = signal<Tag>(null);
  hasDrafts = signal(false);

  items$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    switchMap((user) => user.role === Roles.worker ?
      combineLatest([
        of([user]), this.schedulesService.getList({ user: user.guid }),
        toObservable(this.filterArea, { injector: this.injectorContext }),
        toObservable(this.filterTeam, { injector: this.injectorContext }),
        this.areas$,
        this.teams$,
      ]) :
      combineLatest([
        this.usersService.getList(undefined, { role: Roles.worker }),
        this.schedulesService.getList({ contractor: user.contractors[0] }),
        toObservable(this.filterArea, { injector: this.injectorContext }),
        toObservable(this.filterTeam, { injector: this.injectorContext }),
        this.areas$,
        this.teams$,
      ])),
    tap(([_, schedules]) => {
      const hasDrafts = schedules.some((it) => it.times.some((itt) => itt.status === 'draft'));
      this.hasDrafts.set(hasDrafts);
    }),
    map(([users, schedules, area, team, areas, teams]) => users
      .filter((it) => area == null || area.users.some((u) => u.guid === it.guid))
      .filter((it) => team == null || team.users.some((u) => u.guid === it.guid))
      .filter((it) => !it.inactive).map((it) => {
        const userSchedules = schedules.filter((itt) => itt.users.includes(it.guid));
        const usersAreas = areas.filter((itt) => itt.users.some((ittt) => ittt.guid === it.guid));
        const usersTeams = teams.filter((itt) => itt.users.some((ittt) => ittt.guid === it.guid));
        return {
          guid: it.guid, name: it.displayName, fullHours: it.workType === 'fullTime',
          schedules: userSchedules,
          areas: usersAreas.map((itt) => itt.name).join(','),
          teams: usersTeams.map((itt) => itt.name).join(','),
        };
      }).sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  areas$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.tagsService.getList({ contractor: contractor.guid, type: 'area' })),
    map((items) => items.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  teams$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.tagsService.getList({ contractor: contractor.guid, type: 'team' })),
    map((items) => items.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  roleS = computed(() => this.usersService.currentUserS()?.role);

  private getDays(mobileView: boolean, selectedDay: Date) {
    const days: { date: Date; dateString: string; isToday: boolean; isWeekend: boolean; isPeriodStart: boolean; isPeriodEnd: boolean }[] = [];

    const levelingPeriodStart = this.contractorsService.contractorS().usersOptions?.levelingPeriodStart.toDate() ?? startOfWeek(startOfToday(), { weekStartsOn: 1 });
    const levelingPeriodWeeks = this.contractorsService.contractorS().usersOptions?.levelingPeriodWeeks ?? defaultLevelingPeriodWeeks;

    const maxDate = mobileView ? addDays(selectedDay, 5) : addWeeks(selectedDay, 2);

    const period = getCurrentLevelingPeriod(selectedDay, levelingPeriodStart, levelingPeriodWeeks);

    let currentDate = selectedDay;
    while (isBefore(currentDate, maxDate)) {
      const item = {
        date: currentDate,
        dateString: currentDate.toLocaleDateString('fi'),
        isToday: currentDate.toISOString() === startOfToday().toISOString(),
        isWeekend: currentDate.getDay() === 0 || currentDate.getDay() === 6,
        isPeriodStart: currentDate.toLocaleDateString('fi') === period.start.toLocaleDateString('fi'),
        isPeriodEnd: currentDate.toLocaleDateString('fi') === period.end.toLocaleDateString('fi'),
      };
      days.push(item);
      currentDate = addDays(currentDate, 1);
    }

    return days;
  }

  constructor() {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.mobileView.set(window.innerWidth < mobileWidth);
  }

  getItem(date: string, user: string, schedules: Schedule[]) {
    const schedule = schedules?.find((it) => it.date === date) ?? null;
    if (schedule != null) {
      const time = schedule.times.find((it) => it.user === user);
      return { schedule, time };
    }
    return { schedule, time: null };
  }

  onDateChange(date: Date) {
    this.selectedDayS.set(date);
  }

  onUser(item: SubItem) {
    this.navigationService.navigateToUser(item.guid);
  }

  async onFilterTag(type: string) {
    if (type === 'area') {
      const clearButton = this.filterArea() != null;
      const items = await firstValueFrom(this.areas$);
      this.modalCtrl.create({ component: SelectDialogComponent<Tag>, componentProps: { items, clearButton } }).then((m) => {
        m.present();

        m.onDidDismiss().then((data) => {
          if (data.data) {
            if (data.data.clear) {
              this.filterArea.set(null);
            } else {
              this.filterArea.set(data.data);
            }
          }
        });
      });
    } else if (type === 'team') {
      const clearButton = this.filterTeam() != null;
      const items = await firstValueFrom(this.teams$);
      this.modalCtrl.create({ component: SelectDialogComponent<Tag>, componentProps: { items, clearButton } }).then((m) => {
        m.present();

        m.onDidDismiss().then((data) => {
          if (data.data) {
            if (data.data.clear) {
              this.filterTeam.set(null);
            } else {
              this.filterTeam.set(data.data);
            }
          }
        });
      });
    }
  }

  onItem(date: Date, user: SubItem, schedules: Schedule[]) {
    const schedule = schedules.find((it) => it.date === date.toLocaleDateString('fi'));
    if (schedule) {
      this.modalCtrl.create({ component: ScheduleDialogComponent, componentProps: { schedule, user } }).then((m) => {
        m.present();
      });
    } else {
      this.modalCtrl.create({ component: AddScheduleDialogComponent, componentProps: { user, date, machines: [] } }).then((m) => {
        m.present();

        m.onDidDismiss().then(async (data) => {
          if (data.data) {
            const siteSchedule = await firstValueFrom(this.schedulesService.getSingle({ site: data.data.site?.guid ?? null, date: date.toLocaleDateString('fi') }));
            data.data.status = 'draft';
            this.schedulesService.handleAddSchedule(data.data, { guid: user.guid, displayName: user.name, role: Roles.worker }, siteSchedule);
          }
        });
      });
    }
  }

  onPublish(items: { schedules: Schedule[] }[]) {
    const schedules = items.map((it) => it.schedules).flat().filter((it) => it.times.some((itt) => itt.status === 'draft'));
    const hasDrafts = Array.from(new Set(schedules));
    hasDrafts.map((it) => {
      it.times.map((itt) => {
        itt.status = 'published';
      });
      this.schedulesService.updateOnly(it.guid, { times: it.times });
    });
  }
}
