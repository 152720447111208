@if (!ownSites()) {
  <div class="ion-margin-bottom">
    <ion-button fill="outline"
      shape="round"
      (click)="onNew()">
      <ion-icon slot="start"
        name="add-outline"></ion-icon>
      {{ 'timesheet.new' | translate }}
    </ion-button>
  </div>
  <div class="days ion-margin-bottom ion-margin-top">
    <app-selected-day [currentDay]="startDay()"
      id="startTs"
      (changed)="onDayChange($event, true)"></app-selected-day>
    <div>-</div>
    <app-selected-day [currentDay]="endDay()"
      id="endTs"
      (changed)="onDayChange($event, false)"></app-selected-day>
  </div>
}
@if (timesheets$ | async; as timesheets) {
  @for (worker of timesheets; track worker) {
    <h4>
      {{ worker.site.name }}
    </h4>
    @for (timesheet of worker.timesheets; track timesheet) {
      <ion-item button="true"
        lines="none"
        (click)="onTimesheet(timesheet)">
        <ion-icon slot="start"
          name="calendar-clear-outline"></ion-icon>
        <div>
          <div>
            <div>
              {{ timesheet.username }}
              @if (timesheet.flagged) {
                <ion-icon color="warning"
                  name="warning-outline"></ion-icon>
              }
            </div>
            @if (!ownSites()) {
              @if (timesheet?.dateIn) {
                <span>
                  {{ timesheet.dateIn | timestamp:'HH:mm' }}
                </span>
              }
              <span> - </span>
              @if (timesheet?.dateOut) {
                <span>
                  {{ timesheet.dateOut | timestamp:'HH:mm' }}
                </span>
              }
            }
          </div>
          @if (timesheet.overtime) {
            <span class="overtime">{{ timesheet.overtime }}</span>
          }
        </div>
        <div slot="end">
          @if (timesheet?.dateIn) {
            <div>
              {{ timesheet.dateIn | timestamp:'dd.MM.yyyy' }}
            </div>
          }
          @if (timesheet?.dateIn && timesheet?.dateOut) {
            {{ (timesheet.dateOut.getTime() - timesheet.dateIn.getTime()) | duration }}
          }
        </div>
      </ion-item>
    }
  } @empty {
    <app-no-results></app-no-results>
  }
}