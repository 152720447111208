import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { IonToggleCustomEvent, ToggleChangeEventDetail } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { fieldSorter } from '@scandium-oy/ngx-scandium';
import {
  addHours, addMinutes, differenceInBusinessDays, differenceInCalendarDays, eachWeekendOfMonth, endOfDay, getDaysInMonth, isAfter, startOfDay, startOfToday,
} from 'date-fns';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SegmentListComponent } from 'src/app/components/segment-list/segment-list.component';
import { SelectDayComponent } from 'src/app/components/select-day/select-day.component';
import { SelectSiteButtonComponent } from 'src/app/components/select-site/select-site.component';
import { SelectTicketsDialog } from 'src/app/components/select-tickets/dialog/select-tickets.dialog';
import { workdayCost, workdayHours } from 'src/app/constants/work';
import { LocationService } from 'src/app/location/location.service';
import { Site } from 'src/app/models/site.model';
import { RoomTicket } from 'src/app/models/ticket.model';
import { WorkMachine } from 'src/app/models/work-machine.model';
import { getAbsences } from 'src/app/report/helper';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { ContractorsService } from 'src/app/services/contractors.service';
import { AddScheduleItem } from 'src/app/services/schedules.service';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { SitesService } from 'src/app/services/sites.service';
import { UsersService } from 'src/app/services/users.service';
import { WorkMachinesService } from 'src/app/services/work-machines.service';
import { getDateWithTimezone } from 'src/app/utility/time';

@Component({
  selector: 'app-add-schedule-dialog',
  templateUrl: './add-schedule.dialog.html',
  styleUrls: ['./add-schedule.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainDialogComponent,
    SelectDayComponent,
    SelectSiteButtonComponent,
    SegmentListComponent,
  ],
})
export class AddScheduleDialogComponent {
  private translate = inject(TranslateService);
  private sitesService = inject(SitesService);

  private machinesInUse$: Observable<string[]>;
  private day: number;
  private cost: { period: 'hour' | 'day' | 'week' | 'month'; amount: number };
  private absences = getAbsences(this.translate);

  formGroup: FormGroup;

  role$ = this.usersService.role$.pipe(
    shareReplay(1),
  );

  scheduleSite = signal<Site>(null);
  isWeekend = signal<boolean>(false);
  isEndCleaning = signal(false);
  actualHours$: Observable<number>;
  name = signal('');
  selectedMachine = signal<WorkMachine>(null);

  sites$ = this.sitesService.getActiveList().pipe(
    filter((sites) => sites?.length > 0),
    tap((sites) => {
      if (sites.length === 1) {
        const selectedSite = sites[0];
        if (selectedSite) {
          this.scheduleSite.set(selectedSite);
        }
      }
    }),
    map((sites) => sites.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  machines$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.workMachinesService.getList({ contractor: contractor.guid })),
    switchMap((machines) => combineLatest([of(machines), this.machinesInUse$])),
    map(([machines, inUse]) => machines.filter((m) => !inUse.includes(m.guid))),
    shareReplay(1),
  );

  projects$ = combineLatest([this.contractorsService.getCurrentContractor(), toObservable(this.scheduleSite)]).pipe(
    filter(([contractor, site]) => contractor != null && site != null),
    switchMap(([contractor, site]) => this.siteProjectsService.getList({ contractor: contractor.guid, site: site.guid })),
    map((projects) => projects?.sort(fieldSorter(['name'])) ?? []),
    tap((projects) => this.formGroup.get('project').setValue(projects[0])),
    shareReplay(1),
  );

  workday = signal(workdayHours);
  workdayCost = signal(workdayCost);
  dayCost = signal<string>(null);
  distance = signal(0);
  isUser = signal(true);
  includeWeekend = signal(false);
  selectedTickets = signal<RoomTicket[]>([]);
  viewSegment = signal<string>('allocate');

  readonly viewSegmentButtons = [
    {
      value: 'allocate',
      name: 'schedule.schedule',
      show: true,
    },
    {
      value: 'absence',
      name: 'timesheet.absence',
      show: true,
    },
  ];

  readonly minDate = getDateWithTimezone(startOfToday());

  constructor(
    private contractorsService: ContractorsService,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private siteProjectsService: SiteProjectsService,
    private usersService: UsersService,
    private workMachinesService: WorkMachinesService,
    navParams: NavParams,
  ) {
    this.isUser.set(!navParams.get('isMachine'));
    const site = navParams.get('site');
    this.scheduleSite.set(site);
    const item = navParams.get('user');
    const date: Date = navParams.get('date');
    this.day = date?.getDay() ?? navParams.get('day');
    this.machinesInUse$ = navParams.get('machines');

    const start = addHours(startOfDay(date), 7);
    const end = addMinutes(addHours(start, 8), 30);

    this.formGroup = this.formBuilder.group({
      days: [1, [Validators.required, Validators.min(1)]],
      startDate: [getDateWithTimezone(startOfDay(date))],
      endDate: [getDateWithTimezone(endOfDay(date))],
      text: [''],
      machine: this.isUser() ? [] : [{ guid: item.guid }],
      project: [null, this.isUser() ? Validators.required : Validators.nullValidator],
      start: [getDateWithTimezone(start)],
      end: [getDateWithTimezone(end)],
      absence: [null],
    });

    if (site?.location && item.location) {
      const distance = this.locationService.calculateDistance(item.location, site.location);
      this.distance.set(distance);
    }

    if (item.hourlyRate) {
      this.cost = { period: 'hour', amount: item.hourlyRate };
      this.calculateCost(1);
    } else if (item.cost) {
      this.cost = { period: item.period, amount: item.cost };
      this.calculateCost(1);
    }
    this.name.set(item.displayName ?? item.name);

    this.isWeekend.set(this.day === 0 || this.day === 6);
  }

  private calculateCost(days: number): string {
    let ret = 0;
    if (this.scheduleSite()?.noPaySite) {
      return '0.00';
    }
    if (this.cost?.period === 'hour') {
      if (this.day === 0) {
        ret = days * 8 * 2 * this.cost.amount * 1.35;
      } else if (this.day === 6) {
        ret = days * 4 * this.cost.amount * 1.35 + (4 * this.cost.amount * 2);
      } else {
        ret = days * 8 * this.cost.amount * 1.35;
      }
    } else if (this.cost?.period === 'day') {
      ret = days * this.cost.amount;
    } else if (this.cost?.period === 'week') {
      ret = days * (this.cost.amount / 5);
    } else if (this.cost?.period === 'month') {
      const weekends = eachWeekendOfMonth(startOfToday()).length;
      ret = days * (this.cost.amount / (getDaysInMonth(startOfToday()) - weekends));
    }
    this.dayCost.set(ret.toFixed(2));
  }

  private setDays(includeWeekend: boolean) {
    const startDate = startOfDay(new Date(this.formGroup.get('startDate').value));
    let endDate = startOfDay(new Date(this.formGroup.get('endDate').value));
    if (isAfter(startDate, endDate)) {
      this.formGroup.get('endDate').setValue(getDateWithTimezone(startDate));
      endDate = startOfDay(new Date(this.formGroup.get('endDate').value));
    }
    let days = 1;
    if (includeWeekend) {
      days = differenceInCalendarDays(endDate, startDate) + 1;
    } else {
      days = differenceInBusinessDays(endDate, startDate) + 1;
    }
    this.calculateCost(days);
    this.formGroup.get('days').setValue(days);
  }

  onIncludeWeekend(event: IonToggleCustomEvent<ToggleChangeEventDetail<unknown>>) {
    this.includeWeekend.set(event.detail.checked);
    this.setDays(this.includeWeekend());
  }

  onDateChange(value: string, field: string) {
    this.formGroup.get(field).setValue(value);
    this.setDays(this.includeWeekend());
  }

  selectMacines(items: WorkMachine[]) {
    this.modalCtrl.create({ component: SelectDialogComponent<WorkMachine>, componentProps: { items } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('machine').setValue(null);
            this.selectedMachine.set(null);
          } else {
            this.formGroup.get('machine').setValue({ guid: data.data.guid });
            this.selectedMachine.set(data.data);
          }
        }
      });
    });
  }

  selectTickets() {
    this.modalCtrl.create({ component: SelectTicketsDialog, componentProps: { site: this.scheduleSite() } }).then((m) => {
      m.present();

      m.onDidDismiss<{ tickets: RoomTicket[] }>().then((data) => {
        if (data.data) {
          this.selectedTickets.set(data.data.tickets);
        }
      });
    });
  }

  onAbsence() {
    const items = this.absences;
    const clearButton = this.formGroup.get('absence').value != null;
    this.modalCtrl.create({ component: SelectDialogComponent, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('absence').setValue(null);
          } else {
            this.formGroup.get('absence').setValue(data.data.key);
          }
        }
      });
    });
  }

  onSave() {
    const value = this.formGroup.value;
    const days = value.days;
    const text = value.text;
    const machine = value.machine?.guid ?? null;
    const project = this.viewSegment() === 'allocate' ? value.project?.guid ?? null : null;
    const contractor = this.contractorsService.contractorS().guid;
    const startDate = new Date(this.formGroup.get('startDate').value);
    const includeWeekend = this.includeWeekend();
    const roomTickets = this.selectedTickets();
    const site = this.viewSegment() === 'allocate' ? this.scheduleSite() : null;
    const absence = this.viewSegment() === 'absence' ? value.absence : null;
    this.dismiss({
      days, text, machine, project, start: new Date(value.start), end: new Date(value.end), contractor, startDate, includeWeekend, roomTickets, site, absence, status: 'published',
    });
  }

  async dismiss(item?: AddScheduleItem) {
    return this._modal.dismiss(item);
  }
}
