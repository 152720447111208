import { CommonModule } from '@angular/common';
import { Component, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular/standalone';
import { addYears, format, startOfToday } from 'date-fns';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { NoResultsComponent } from 'src/app/components/no-results/no-results.component';
import { SelectedDayComponent } from 'src/app/day/selected-day/selected-day.component';
import { Site } from 'src/app/models/site.model';
import { IWorkerTimesheets, Timesheet } from 'src/app/models/timesheet.model';
import { DurationPipe } from 'src/app/pipes/duration/duration.pipe';
import { TimestampPipe } from 'src/app/pipes/timestamp/timestamp.pipe';
import { SitesService } from 'src/app/services/sites.service';
import { TimesheetsService } from 'src/app/services/timesheets.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';
import { fieldSorter } from 'src/app/utility/sort';
import { NewTimesheetDialogComponent } from '../../timesheets/new-timesheet/new-timesheet.dialog';
import { millisToMinutesAndSeconds, selectedDayFormat } from '../../utility/time';
import { TimesheetDialogComponent } from '../timesheet/timesheet.dialog';


@Component({
  selector: 'app-timesheets',
  templateUrl: './timesheets.component.html',
  styleUrls: ['./timesheets.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    AppCommonModule,
    SelectedDayComponent,
    DurationPipe,
    NoResultsComponent,
    TimestampPipe,
  ],
})
export class TimesheetsComponent {
  private startDate$ = new ReplaySubject<Date>(1);
  private endDate$ = new ReplaySubject<Date>(1);
  private sites$: BehaviorSubject<Site[]> = new BehaviorSubject([]);
  private users$ = this.usersService.getList();

  ownSites = input(false);

  startDay = signal('');
  endDay = signal('');
  timesheets$ = combineLatest([this.startDate$, this.endDate$, this.usersService.getCurrentUser(), this.sitesService.isSiteLead$]).pipe(
    filter(([startDate, endDate, user]) => !!startDate && !!endDate && user != null),
    switchMap(([startDate, endDate, user, isSitelead]) => combineLatest(
      [
        this.timesheetsService.getList({
          startDate,
          endDate,
          checked: false,
          contractor: user.contractors[0],
          sites: isSitelead ? this.sitesService.getSiteleadSites().map((s) => s.guid) : undefined,
        }),
        this.users$,
      ]),
    ),
    map(([timesheets, users]) => {
      if (this.ownSites()) {
        timesheets = timesheets.filter((ts) => this.sites$.value.map((s) => s.guid).includes(ts.site));
      }
      const workers: IWorkerTimesheets[] = [];
      timesheets.map((timesheet) => {
        const user = users.find((u) => u.guid === timesheet.user);
        const site = this.sites$.value.find((it) => it.guid === timesheet.site);
        const old = workers.find((w) => w.guid === site.guid);
        timesheet.username = timesheet.username ?? user?.displayName;
        if (old) {
          old.timesheets.push(timesheet);
        } else {
          workers.push({
            guid: site.guid,
            site,
            timesheets: [timesheet],
            siteName: site.name.toLowerCase(),
          });
        }
      });
      return workers.sort(fieldSorter(['siteName']));
    }),
    shareReplay(1),
  );

  millisToMinutesAndSeconds = millisToMinutesAndSeconds;

  constructor(
    private modalCtrl: ModalController,
    private sitesService: SitesService,
    private timesheetsService: TimesheetsService,
    private usersService: UsersService,
  ) {
    combineLatest([this.sitesService.getAdminList(), this.usersService.getCurrentUser()]).pipe(
      filter(([sites, user]) => sites != null && user != null),
      map(([sites, user]) => {
        if (this.ownSites()) {
          const ownSites = sites.filter((site) => [Roles.admin].includes(user.role) || site.supervisor?.some((u) => u.guid === user.guid) || this.usersService.isSitelead(site));
          return ownSites;
        } else {
          return sites;
        }
      }),
      take(1),
      takeUntilDestroyed(),
    ).subscribe((sites) => {
      this.sites$.next(sites);
      this.selectToday();
    });
  }

  private selectToday() {
    const now = startOfToday();
    const start = addYears(now, -5);
    this.onDayChange(start, true);
    this.onDayChange(now, false);
  }

  onDayChange(newdate: Date, start: boolean) {
    if (start) {
      this.startDay.set(format(newdate, selectedDayFormat));
      this.startDate$.next(newdate);
    } else {
      this.endDay.set(format(newdate, selectedDayFormat));
      this.endDate$.next(newdate);
    }
  }

  onTimesheet(timesheet: Timesheet) {
    this.modalCtrl
      .create({ component: TimesheetDialogComponent, componentProps: { timesheet, sites: this.sites$.value } })
      .then((m) => {
        m.present();
      });
  }

  onNew() {
    this.modalCtrl
      .create({ component: NewTimesheetDialogComponent })
      .then((m) => {
        m.present();
        m.onDidDismiss().then((data) => {
          if (data.data) {
            const timesheet = data.data;
            this.timesheetsService.save(timesheet, true);
          }
        });
      });
  }
}
